<ng-container>
	<mat-card class="example-card">
		<mat-card-header>
			<mat-card-title>{{page.title}}</mat-card-title>
			<mat-card-subtitle class="autor">{{page?.user?.username}}</mat-card-subtitle>
			<mat-card-subtitle class="date">{{page.created_at | formattedDate}}</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content [innerHTML]="page.body"></mat-card-content>
		<mat-card-actions>
			<button mat-button trans (click)="backToList()">BACK</button>
		</mat-card-actions>
	</mat-card>
</ng-container>
