<ng-container *ngIf="dataSource.paginatedOnce$ | async">
    <header>
        <h1><span trans>Blog</span></h1>
        <div class="right-column">
			<div class="input-container">
				<mat-select multiple placeholder="Todas as categorias" id="categories" trans-placeholder (selectionChange)="choiseCategories($event)">
					<mat-option *ngFor="let category of (categories | async).categories" [value]="category.id">{{category.name}}</mat-option>
				</mat-select>
			</div>
        </div>
    </header>

    <ad-host slot="ads.general_top" class="margin-bottom"></ad-host>
</ng-container>

<loading-indicator [isVisible]="dataSource.loading$ | async" class="inline"></loading-indicator>

<mat-card
	class="example-card" *ngFor="let article of article$ | async"
>
	<mat-card-header>
		<mat-card-title>{{article.title}}</mat-card-title>
		<mat-card-subtitle class="date">{{article.user.username}} - {{article.created_at | formattedDate}}</mat-card-subtitle>
	</mat-card-header>
	<mat-card-content>
		<p>{{article.resume}}</p>
	</mat-card-content>
	<mat-card-actions>
		<button mat-button trans (click)="cardLink(article)">Leia mais</button>
	</mat-card-actions>
</mat-card>


<div class="no-results-message" *ngIf="dataSource.noResults$ | async">
    <mat-icon class="icon" svgIcon="mic"></mat-icon>
    <div class="text">
        <div class="main" trans>Nothing to display.</div>
        <div class="secondary" trans>Nenhum artigo foi postado no blog.</div>
    </div>
</div>
