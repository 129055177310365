import {CategoryBlog} from '@common/shared/category-blog/models/category-blog';
import {Injectable} from '@angular/core';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CategoriesService {

	/**
	 * Subscriptions constructor.
	 */
	constructor(private http: AppHttpClient) {}

	public update(id: number, payload: Partial<CategoryBlog>) {
		return this.http.put('category/blog/' + id, payload);
	}

	/**
	 * Create payload
	 * @param payload
	 */
	public create(payload: Partial<CategoryBlog>): Observable<{category: CategoryBlog}> {
		return this.http.post('category/blog', payload);
	}

	public all(): Observable<{categories: CategoryBlog[]}> {
		return this.http.get('category/blog/all');
	}
}
