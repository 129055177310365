<form class="auth-page" id="auth-page" (ngSubmit)="resetPassword()" *ngIf="errors$ | async as errors" [formGroup]="form" ngNativeValidate>
    <div class="many-inputs auth-panel">
        <loading-indicator class="overlay" [isVisible]="loading$ | async"></loading-indicator>

        <a class="logo" [routerLink]="['/']"><img [src]="settings.get('branding.logo_dark')" alt="logo"></a>

        <div class="input-container">
            <label for="email" class="hidden" trans>Email</label>
            <input type="email" formControlName="email" id="email" required placeholder="Email" trans-placeholder>
            <p class="error email-error" *ngIf="errors.email" trans>{{errors.email}}</p>
        </div>

        <div class="input-container">
            <label for="password" class="hidden" trans>New Password</label>
            <input type="password" formControlName="password" id="password" required placeholder="Password" trans-placeholder>
            <p class="error password-error" *ngIf="errors.password" trans>{{errors.password}}</p>
        </div>

        <div class="input-container">
            <label for="password_confirmation" class="hidden" trans>Confirm New Password</label>
            <input type="password" formControlName="password_confirmation" name="password_confirmation" id="password_confirmation" required placeholder="Confirm Password" trans-placeholder>
        </div>

        <div class="right">
            <button mat-raised-button color="accent" type="submit" trans>Reset Password</button>
        </div>
    </div>
</form>
