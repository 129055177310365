import {Component, ElementRef, NgZone, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Article} from '../../../models/Article';
import {WebPlayerUrls} from '../../web-player-urls.service';
import {Paginator} from '@common/shared/paginator.service';
import {InfiniteScroll} from '@common/core/ui/infinite-scroll/infinite.scroll';
import {PaginatedDataTableSource} from '@common/shared/data-table/data/paginated-data-table-source';
import {WebPlayerState} from '../../web-player-state.service';
import {Observable} from 'rxjs';
import {MatColumnDef, MatTable} from '@angular/material/table';
import {Track} from '../../../models/Track';
import {CategoryBlog} from '@common/shared/category-blog/models/category-blog';
import {CategoriesService} from '@common/shared/category-blog/categories.service';
import {Router} from '@angular/router';
import {bindSlugTo} from '@common/shared/form-controls/slug-control/bind-slug-to';
import slugify from 'slugify';

@Component({
    selector: 'blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss'],
    providers: [Paginator],
})
export class BlogComponent extends InfiniteScroll implements OnInit, OnDestroy {
	@ViewChildren('trackListRow') trackListRows: QueryList<ElementRef>;
	@ViewChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

    public dataSource: PaginatedDataTableSource<Article>;
    public article$: Observable<Article[]>;
	public columns: string[] = [];
	public categories: Observable<{ categories: CategoryBlog[] }>;

    constructor(
        private paginator: Paginator<Article>,
		private router: Router,
        public urls: WebPlayerUrls,
        public state: WebPlayerState,
        protected zone: NgZone,
		public categoriesService: CategoriesService,
    ) {
        super();
    }

    ngOnInit() {
        this.paginator.dontUpdateQueryParams = true;
        this.dataSource = new PaginatedDataTableSource<Article>({
            uri: 'blog/articles',
            dataPaginator: this.paginator,
            appendData: true,
        }).init();
        this.el = this.state.scrollContainer;
        this.article$ = this.dataSource.connect();
        super.ngOnInit();
		this.categories = this.getCategories()
    }

	public getCategories(){
		return this.categoriesService.all()
	}

	ngAfterViewInit() {
		this.columnDefs.forEach(columnDef => {
			this.columns.push(columnDef.name);
		});
	}

    ngOnDestroy() {
        this.dataSource.disconnect();
        super.ngOnDestroy();
    }

    public choiseCategories($event) {
		console.log('event', $event, {category_id: {... $event.value}}, $event.value.join(","))
		const articles = this.dataSource.getData().filter((article) => article.category_id = 1);
    	this.dataSource.setData(articles)
		this.dataSource.reload({category_id: $event.value.join(",")})
    }

    public canLoadMore() {
        return this.dataSource.canLoadNextPage();
    }

    public cardLink(article: Article) {
    	const title = slugify(article.title);
		this.router.navigate([`/blog/${article.id}/${title}`]);
	}

    protected isLoading() {
        return this.dataSource.loading$.value;
    }

    protected loadMoreItems() {
        this.dataSource.loadNextPage();
    }

    public trackByFn = (i: number, article: Article) => article.id;

	public toggleAllSelection = () => {}
}
