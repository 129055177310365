<div class="modal-header">
    <h2 class="modal-title" mat-dialog-title trans>Credentials Required</h2>
    <button type="button" (click)="close()" class="close-button no-style" tabindex="-1">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<form (ngSubmit)="confirm()" [formGroup]="form" *ngIf="errors$ | async as errors" ngNativeValidate>
    <mat-dialog-content>
        <div class="many-inputs">
            <div class="input-container email-address-container" *ngIf="shouldCredentialBeRequested('email')">
                <label for="email" trans>Email Address</label>
                <input type="email" id="email" formControlName="email" required>
                <div class="error" *ngIf="errors.email" trans>{{errors.email}}</div>
                <p trans>We need your email address to create an account for you, please enter it above.</p>
            </div>

            <div class="input-container password-container" *ngIf="shouldCredentialBeRequested('password')">
                <label for="password" trans>Password</label>
                <input type="password" id="password" formControlName="password" required>
                <div class="error" *ngIf="errors.password" trans>{{errors.password}}</div>
                <p trans>An account with this email address already exists, if you want to connect the two account's please enter existing accounts password above.</p>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button (click)="close()" type="button" mat-button trans>Cancel</button>
        <button type="submit" mat-raised-button color="accent" trans>Connect</button>
    </mat-dialog-actions>
</form>
