import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Pages} from '@common/core/pages/shared/pages.service';
import {CustomPage} from '@common/core/types/models/CustomPage';

@Component({
    selector: 'article',
    templateUrl: './article.component.html',
    styleUrls: ['./article.component.scss'],
})
export class ArticleComponent implements OnInit {

	public page: CustomPage

    constructor(
        protected zone: NgZone,
		private route: ActivatedRoute,
		private pages: Pages,
		private router: Router
    ) {
    }

    ngOnInit() {
		this.route.params.subscribe(params => {
			const id = params.id || this.route.snapshot.data.id;
			this.pages.get(id).subscribe(response => {
				this.page = response.page;
			}, () => {
				this.router.navigate(['/404'], {skipLocationChange: true});
			});
		});
    }

    backToList() {
		this.router.navigate(['/blog'])
	}
}
